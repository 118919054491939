import React, { useEffect, useState } from "react";
import HTMLReactParser from "html-react-parser";

import ShortcodeParserService from "../services/shortcode-parser-service";
import leadsFormsMap from "../config/leads-forms-map.tsx";
import ComponentsMap from "../config/components-map.tsx";
import { FormWrapper } from "../components/leads-components/form-wrapper";
import Layout from "../components/layout";
import HTMLParser from "../components/html-parser";

export default ({ pageContext: { uri, title, content, seo } }) => {
  const [parsedContent, setParsedContent] = useState();
  const componentsMap = { ...ComponentsMap, ...leadsFormsMap };

  useEffect(() => {
    setParsedContent(
      HTMLReactParser(content, {
        replace: (node) => {
          if (node.data) {
            const shortcodes = ShortcodeParserService.extractWpShortcode(
              node.data
            );

            if (shortcodes) {
              const shortcodeParsed = ShortcodeParserService.parse(
                shortcodes[0]
              );

              if (shortcodeParsed.name === "FormWrapper") {
                return (
                  <FormWrapper {...shortcodeParsed.attributes}></FormWrapper>
                );
              } else {
                return ShortcodeParserService.createComponent(
                  shortcodeParsed,
                  componentsMap
                );
              }
            }
          }
        },
      })
    );
  }, []);

  return (
    <Layout seo={seo}>
      <article className="py-12 md:pl-5 w-full max-w-none">
        <h1 className="mb-12">{title}</h1>
        <span className="prose lg:prose">
          {!parsedContent ? <HTMLParser>{content}</HTMLParser> : parsedContent}
        </span>
      </article>
    </Layout>
  );
};
